/**
 * @author   André van Toly
 * @since    May 2009
 * @name     Stylesheet, less version, to style OIPlayer and its controls
 */
/* webfont with icons */
@font-face {
  font-family: "oiplayer-webfont";
  src: url('icons/oiplayer-webfont.eot');
  src: url('icons/oiplayer-webfont.eot?#iefix') format('eot'), url('icons/oiplayer-webfont.woff2') format('woff2'), url('icons/oiplayer-webfont.woff') format('woff'), url('icons/oiplayer-webfont.ttf') format('truetype'), url('icons/oiplayer-webfont.svg#oiplayer-webfont') format('svg');
}
[class^="icon-"],
[class*=" icon-"],
.icon-base-pseudo,
.oipcontrols .play a:before,
.oipcontrols .play.pause a:before,
.oipcontrols .screen a:before,
.oipcontrols .sound a:before,
.oipcontrols .sound.muted a:before {
  display: inline-block;
  vertical-align: middle;
  font-family: "oiplayer-webfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  font-size: 1rem;
  line-height: 1;
}
.icon-fullscreen:before {
  content: "\E001";
}
.icon-mute:before {
  content: "\E002";
}
.icon-pause:before {
  content: "\E003";
}
.icon-play:before {
  content: "\E004";
}
.icon-position:before {
  content: "\E005";
}
.icon-sound:before {
  content: "\E006";
}
/* variables */
.oiplayer {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
  margin: 0;
  padding: 0;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}
.oiplayer *,
.oiplayer *::before,
.oiplayer *::after {
  box-sizing: inherit;
}
.oiplayer a:focus {
  outline: none;
  background-color: transparent;
}
.oiplayer.fullscreen {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  z-index: 1000;
  width: 100%;
  height: 100%;
  background-color: #000;
}
.oiplayer.fullscreen .player {
  width: 100%;
  height: 100%;
  z-index: 1001;
}
.oiplayer.fullscreen .player video,
.oiplayer.fullscreen .player object,
.oiplayer.fullscreen .player embed {
  z-index: 1002;
}
.oiplayer video,
.oiplayer object,
.oiplayer embed {
  display: block;
  margin: 0;
  padding: 0;
  border: 0;
}
.oiplayer audio {
  position: absolute;
  bottom: 0;
  left: 0;
  display: block;
  margin: 0;
  padding: 0;
  width: 100%;
  /* not sure about this */
}
.oiplayer .player {
  margin: 0;
  padding: 0;
  z-index: 10;
}
.audio .oiplayer .player {
  bottom: 0;
}
.oiplayer .preview {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 11;
  cursor: pointer;
}
.oiplayer.inavailable.video {
  background: url("images/preview_video.png") left top repeat;
}
.oiplayer.inavailable.audio {
  background: url("images/preview_audio.png") left top repeat;
}
.oiplayer.inavailable div.player {
  display: none;
}
.oiplayer.inavailable p {
  width: 80%;
  text-align: center;
  font-size: 1.4em;
  margin: 0 auto;
  padding-top: 32%;
  font-weight: bold;
}
.oipcontrols {
  height: 30px;
  line-height: 25px;
  margin: 0;
  max-width: 640px;
  color: #555;
  z-index: 19;
  background-color: rgba(255, 255, 255, 0.85);
  position: absolute;
  bottom: 0;
  left: 0;
  /* matches the slider when the volume button is hovered */
  /* ### progress bar & position ### */
}
.fullscreen .oipcontrols {
  z-index: 1003;
  margin: 0 auto;
}
.oipcontrols.top {
  margin: auto auto 10px auto;
  border-radius: 2px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.5);
}
.oipcontrols.audio .progress {
  right: 100px;
}
.oipcontrols.audio .timeleft {
  right: 30px;
}
.oipcontrols > div {
  position: absolute;
  margin: 2px 0 0 0;
  padding: 0;
  height: 28px;
}
.oipcontrols .play {
  position: absolute;
  top: 0;
  left: 9px;
}
.oipcontrols .play a {
  width: 22px;
  height: 25px;
  color: #808080;
}
.oipcontrols .play a:before {
  content: "\E004";
}
.oipcontrols .play a:before {
  font-size: 19px;
}
.oipcontrols .play a:hover {
  color: #0a0a0a;
}
.oipcontrols .play.pause a:before {
  content: "\E003";
}
.oipcontrols .play.pause a:before {
  font-size: 19px;
}
.oipcontrols .time {
  position: absolute;
  width: 50px;
  left: 23px;
  font-size: 10px;
  font-family: Monaco, "Courier New", monospaced;
  line-height: 28px;
  text-align: right;
}
.oipcontrols .timeleft {
  position: absolute;
  width: 55px;
  right: 59px;
  font-size: 10px;
  font-family: Monaco, "Courier New", monospaced;
  line-height: 28px;
  text-align: left;
}
.audio .oipcontrols .timeleft {
  right: 30px;
}
.oipcontrols .screen {
  position: absolute;
  right: 40px;
}
.ios .oipcontrols .screen {
  right: 6px;
}
.oipcontrols .screen a {
  width: 28px;
  color: #808080;
}
.oipcontrols .screen a:before {
  content: "\E001";
}
.oipcontrols .screen a:before {
  font-size: 17px;
}
.oipcontrols .screen a:hover {
  color: #606060;
}
.oipcontrols .sound {
  position: absolute;
  right: 9px;
}
.oipcontrols .sound a {
  width: 22px;
  color: #808080;
}
.oipcontrols .sound a:before {
  content: "\E006";
}
.oipcontrols .sound a:before {
  font-size: 16px;
}
.oipcontrols .sound a:hover {
  color: #606060;
}
.oipcontrols .sound.muted {
  right: 15px;
}
.oipcontrols .sound.muted a:before {
  content: "\E002";
}
.oipcontrols .sound.muted a:before {
  font-size: 16px;
}
.oipcontrols .sound > .volume {
  width: 12px;
  height: 60px;
  position: relative;
  top: -6px;
  left: 6px;
  /* container for the volume slider */
}
.oipcontrols .sound > .volume .slider {
  width: 100%;
  height: 100%;
  background-color: #808080;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.8);
  /* by default does not register for pointer events
                 so that only the VOL label acts as a hover trigger */
  pointer-events: none;
  /* volume slider is invisible by default */
  opacity: 0;
  transform: translateY(0);
  /* set up the opacity transition */
  transition-property: opacity, transform;
  transition-duration: 0.25s;
}
.oipcontrols div.sound:hover > div.volume .slider,
.oipcontrols div.sound a:hover > div.volume .slider,
.oipcontrols .volume:hover .slider {
  /* turn pointer events back on so that we now only
         hide the slider when we exit the VOL label or the slider */
  pointer-events: auto;
  /* show the slider */
  opacity: 1;
  transform: translateY(-50px);
}
.oipcontrols div.sound:hover > div.volume .slider .fill,
.oipcontrols div.sound a:hover > div.volume .slider .fill,
.oipcontrols .volume:hover .slider .fill {
  height: 100%;
  background-color: #333;
}
.oipcontrols div.sound:hover > div.volume .slider .thumb > div,
.oipcontrols div.sound a:hover > div.volume .slider .thumb > div,
.oipcontrols .volume:hover .slider .thumb > div {
  position: absolute;
  top: 0;
  margin-top: -6px;
  left: -6px;
  width: 24px;
  height: 12px;
  background-color: #CCCCCC;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.8);
}
.oipcontrols div.sound:hover > div.volume .slider .thumb > div:hover,
.oipcontrols div.sound a:hover > div.volume .slider .thumb > div:hover,
.oipcontrols .volume:hover .slider .thumb > div:hover {
  background-color: #EAEAEA;
}
.oipcontrols .progress {
  position: absolute;
  left: 76px;
  /* 10px margin left and right */
  right: 124px;
}
.audio .oipcontrols .progress {
  right: 100px;
}
.oipcontrols .progress .oiprogress {
  background-color: #a6a6a6;
  border-radius: 6px;
  height: 12px;
  position: relative;
  top: 7px;
  left: 5px;
  pointer-events: none;
}
.oipcontrols .progress .pos {
  position: absolute;
  width: 12px;
  height: 12px;
}
.oipcontrols .progress .pos a {
  background-color: #808080;
  border-radius: 6px;
  width: 12px;
  float: left;
  margin: 0 0 0 -3px;
  height: 12px;
}
.oipcontrols .progress .pos a:hover {
  background-color: #606060;
}
.oipcontrols .progress .oiprogress-container {
  position: absolute;
  left: 0;
  right: 6px;
}
.oipcontrols .progress .oiprogress-container .oiprogress-push {
  position: absolute;
  height: 12px;
  pointer-events: auto;
}
.oipcontrols .bar {
  height: 12px;
  border-radius: 6px;
  position: absolute;
  left: 0;
  top: 0;
}
.oipcontrols .bar.back {
  width: 100%;
  background-color: #ffffff;
  pointer-events: auto;
}
.oipcontrols .bar.loaded {
  height: auto;
  background-color: #cccccc;
  pointer-events: auto;
}
.oipcontrols .bar.played {
  background-color: #a6a6a6;
  pointer-events: none;
}
.oipcontrols.dark {
  color: #efefef;
  background-color: rgba(0, 0, 0, 0.85);
}
.oipcontrols.dark .play a,
.oipcontrols.dark .screen a,
.oipcontrols.dark .sound a {
  color: white;
}
.oipcontrols.dark .play a:hover,
.oipcontrols.dark .screen a:hover,
.oipcontrols.dark .sound a:hover {
  color: #a6a6a6;
}
.oipcontrols.dark .pos a {
  background-color: #606060;
}
.oipcontrols.dark .pos a:hover {
  background-color: #303030;
}
.oipcontrols.dark .progress.changed .oiprogress {
  background-color: #a6a6a6;
}
.oipcontrols.dark .back {
  background-color: #ffffff;
}
.oipcontrols.dark .loaded {
  background-color: #cccccc;
}
.oipcontrols.dark .played {
  background-color: #a6a6a6;
}
.oipcontrols.ios {
  position: relative;
  margin: 0;
  padding: 0;
  /* on iOS sound (iPad) or screen (iPhone) are hidden */
  /* ios: bit different color scheme */
}
.oipcontrols.ios .progress {
  right: 100px;
}
.oipcontrols.ios .timeleft {
  right: 30px;
}
.oipcontrols.ios .screen {
  right: 6px;
}
.oipcontrols.ios .back {
  background-color: #cccccc;
}
.oipcontrols.ios .loaded {
  background-color: #a6a6a6;
}
.oipcontrols.ios .played {
  background-color: #808080;
}
.oipcontrols.ios.dark .back {
  background-color: #ffffff;
}
.oipcontrols.ios.dark .loaded {
  background-color: #cccccc;
}
.oipcontrols.ios.dark .played {
  background-color: #a6a6a6;
}
.oiplayerinfo {
  font-size: xx-small;
  padding: 2px;
  background-color: #efefef;
  color: black;
  z-index: 99;
  border-top: 1px solid #bbb;
  font-family: Monaco, "Courier New", monospaced;
  overflow: auto;
  position: absolute;
  top: 0;
  left: 0;
}
.oiplayerinfo.bottom {
  position: static;
  margin-top: 32px;
}
/* MSIE Java plugin detection, sigh :-( */
#clientcaps {
  behavior: url(#default#clientCaps);
  display: none;
}
/* ### example html ### */
body.oiplayer-example {
  font-size: 0.85em;
  font-family: Helvetica, sans-serif;
  background-color: #efefef;
  line-height: 1.4em;
}
body.oiplayer-example a {
  color: #c00;
}
body.oiplayer-example a:visited {
  color: #333;
}
body.oiplayer-example a:hover,
body.oiplayer-example a:active {
  color: #c00;
  text-decoration: underline;
}
body.oiplayer-example > div.main {
  width: 640px;
  margin: 0 auto;
  background-color: #fff;
  padding: 1px 1em;
}
body.oiplayer-example > div.main ol {
  padding-left: 20px;
}
body.oiplayer-example > div.main .license {
  font-size: 0.8em;
}
